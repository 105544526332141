import { axios } from '@/utils/request'

/**
 * 查询公文流转单据主表
 *
 * @author yx
 * @date 2022-06-10 18:16:00
 */
export function documentBillPage (parameter) {
  return axios({
    url: '/flow/documentBill/page',
    method: 'get',
    params: parameter
  })
}

/**
 * 获取已登记的公文列表
 * @param {*} parameter 分页信息
 * @returns
 */
export function documentRegisterBillPage (parameter) {
  return axios({
    url: '/flow/documentBill/register',
    method: 'get',
    params: parameter
  })
}

/**
 * 查询公文流转单据主表
 *
 * @author xyl
 * @date 2022-06-10 18:16:00
 */
 export function documentBillDispatchPage (parameter) {
  return axios({
    url: '/flow/documentBill/dispatchPage',
    method: 'get',
    params: parameter
  })
}

/**
 * 查询公文流转单据主表
 *
 * @author xyl
 * @date 2022-06-10 18:16:00
 */
 export function documentBillIndexPage (parameter) {
  return axios({
    url: '/flow/documentBill/indexPage',
    method: 'get',
    params: parameter
  })
}

/**
 * 查询是否显示引导弹框
 *
 * @author xyl
 * @date 2022-06-10 18:16:00
 */
 export function getPopup () {
  return axios({
    url: '/flow/documentHomePopup/getPopup',
    method: 'get'
  })
}

/**
 * 设置是否显示引导弹框
 *
 * @author xyl
 * @date 2022-06-10 18:16:00
 */
 export function closePopup (data) {
  return axios({
    url: '/flow/documentHomePopup/closePopup',
    method: 'post',
    data
  })
}

/**
 * 初始化首页数值
 *
 * @author xyl
 * @date 2022-06-10 18:16:00
 */
 export function pendingNumber () {
  return axios({
    url: '/flow/documentBill/pending',
    method: 'get'
  })
}

/**
 * 初始化首页数值
 *
 * @author xyl
 * @date 2022-06-10 18:16:00
 */
 export function statisticsNumber () {
  return axios({
    url: '/flow/documentBill/statistics',
    method: 'get'
  })
}

/**
 * 接收公文
 *
 * @author xyl
 * @date 2022-06-10 18:16:00
 */
 export function acceptDocumentBill (id) {
  return axios({
    url: `/flow/documentBill/accept/${id}`,
    method: 'post'
  })
}

/**
 * 获取公司id
 *
 * @author xyl
 * @date 2022-06-10 18:16:00
 */
 export function getCorpByUser (params) {
  return axios({
    url: `/wx/getCorpByUser`,
    method: 'get',
    params
  })
}

/**
 * 发起人处理节点（下一步）
 *
 * @author xyl
 * @date 2022-06-10 18:16:00
 */
 export function acceptNextNode (parameter) {
  return axios({
    url: `/flow/documentBill/nextNode`,
    method: 'post',
    data: parameter
  })
}

/**
 * 发起人处理节点（下一步） 王念添加催办接口
 *
 * @author xyl
 * @date 2023-11-9 12:16:00
 */
export function urgingNodeUser(parameter) {
  return axios({
    url: `/flow/documentBill/urging`,
    method: 'post',
    data: parameter
  })
}

/**
 * 发起人处理节点（加签）
 *
 * @author xyl
 * @date 2022-06-10 18:16:00
 */
 export function acceptAddNextNode (parameter) {
  return axios({
    url: `/flow/documentBill/addNode`,
    method: 'post',
    data: parameter
  })
}

/**
 * 发起人处理抄送
 *
 * @author xyl
 * @date 2022-06-10 18:16:00
 */
export const carbonCopyBill = parameter => axios({
  url: `/flow/documentBill/recipient`,
  method: 'post',
  data: parameter
})

/**
 * 查询公文流转单据主表
 *
 * @author xyl
 * @date 2022-06-10 18:16:00
 */
 export function documentBillAuditPage (parameter) {
  return axios({
    url: '/flow/documentBill/auditPage',
    method: 'get',
    params: parameter
  })
}

/**
 * xxxxxxxxxxx
 * @param {*} params
 * @returns
 */
export const documentBillHomePage = params => axios({
  url: '/flow/documentBill/homePage',
  method: 'get',
  params
})

/**
 * 查询公文流转单据主表---我已处理
 *
 * @author xyl
 * @date 2022-06-10 18:16:00
 */
 export function documentBillAuditedPage (parameter) {
  return axios({
    url: '/flow/documentBill/audited',
    method: 'get',
    params: parameter
  })
}

/**
 * 公文流转单据主表列表
 *
 * @author yx
 * @date 2022-06-10 18:16:00
 */
export function documentBillList (parameter) {
  return axios({
    url: '/flow/documentBill/list',
    method: 'get',
    params: parameter
  })
}

/**
 * 添加公文流转单据主表
 *
 * @author yx
 * @date 2022-06-10 18:16:00
 */
export function documentBillAdd (parameter) {
  return axios({
    url: '/flow/documentBill/addRegistration',
    method: 'post',
    data: parameter
  })
}

/**
 * 编辑公文流转单据主表
 *
 * @author yx
 * @date 2022-06-10 18:16:00
 */
export function documentBillEdit (parameter) {
  return axios({
    url: '/flow/documentBill/edit',
    method: 'post',
    data: parameter
  })
}

/**
 * 删除公文流转单据主表
 *
 * @author yx
 * @date 2022-06-10 18:16:00
 */
export function documentBillDelete (parameter) {
  return axios({
    url: '/flow/documentBill/delete',
    method: 'post',
    data: parameter
  })
}

/**
 * 导出公文流转单据主表
 *
 * @author yx
 * @date 2022-06-10 18:16:00
 */
export function documentBillExport (parameter) {
  return axios({
    url: '/flow/documentBill/export',
    method: 'get',
    params: parameter,
    responseType: 'blob'
  })
}

/**
 * 发起审批
 * @author xyl
 * @param {*} parameter
 * @returns
 */
export const createApproval = parameter => axios({
  url: '/flow/documentBill/processes',
  method: 'post',
  data: parameter
})

/**
 * 拟稿发文-发送审批
 * @param {*} parameter
 * @returns
 */
export const addOfficial = parameter => axios({
  url: '/flow/documentBill/addOfficial',
  method: 'post',
  data: parameter
})
/**
 * 拟稿发文-编辑发送审批
 * @param {*} parameter
 * @returns
 */
 export const editOfficial = parameter => axios({
  url: '/flow/documentBill/editOfficial',
  method: 'post',
  data: parameter
})

/**
 * 拟稿发文-保存草稿
 * @param {*} parameter
 * @returns
 */
 export const addOfficialDraft = data => axios({
  url: '/flow/documentBill/addOfficialDraft',
  method: 'post',
  data
})
/**
 * 拟稿发文-编辑保存草稿
 * @param {*} parameter
 * @returns
 */
 export const editOfficialDraft = data => axios({
  url: '/flow/documentBill/editOfficialDraft',
  method: 'post',
  data
})

/**
 * 删除公文
 * @author xyl
 * @param {*} parameter
 * @returns
 */
export const delDocumentBill = parameter => axios({
  url: '/flow/documentBill/delete',
  method: 'post',
  data: parameter
})

export function documentBillDetail (parameter) {
  return axios({
    url: '/flow/documentBill/detail',
    method: 'get',
    params: parameter
  })
}

export function documentBillAllPage (parameter) {
  return axios({
    url: `/flow/documentBill/allPage`,
    method: 'get',
    params: parameter
  })
}

/**
 * 审批
 * @author xyl
 * @param {*} parameter
 * @returns
 */
export const documentBillAudit = parameter => axios({
  url: '/flow/documentBill/audit',
  method: 'post',
  data: parameter
})


/**
 * 文书归档列表
 *
 * @author yx
 * @date 2022-06-10 18:16:00
 */
 export function documentArchivedList (parameter) {
  return axios({
    url: '/archived/page',
    method: 'post',
    data: parameter
  })
}

/**
 * 快速发文新增
 *
 * @author yx
 * @date 2022-06-10 18:16:00
 */
 export function documentBillAddDoc (parameter) {
  return axios({
    url: '/flow/documentBill/addDoc',
    method: 'post',
    data: parameter
  })
}

/**
 * 快速发文编辑
 *
 * @author yx
 * @date 2022-06-10 18:16:00
 */
 export function documentBillEditDoc (parameter) {
  return axios({
    url: '/flow/documentBill/editDoc',
    method: 'post',
    data: parameter
  })
}

/**
 * 收文登记编辑
 *
 * @author yx
 * @date 2022-06-10 18:16:00
 */
 export function documentBillEditRegistration (parameter) {
  return axios({
    url: '/flow/documentBill/editRegistration',
    method: 'post',
    data: parameter
  })
}

// 
/**
 * 收文登记编辑
 *
 * @author yx
 * @date 2022-06-10 18:16:00
 */
 export function uploadFileTransform (parameter) {
  return axios({
    url: '/wx/transform',
    method: 'get',
    params: parameter
  })
}
/**
 * 永久删除
 * @param {*} data
 * @returns
 */
export const documentBillExpurgate = data => axios({
  url: '/flow/documentBill/expurgate',
  method: 'post',
  data
})
/**
 * 恢复
 * @param {*} data
 * @returns
 */
export const documentBillCountermand = data => axios({
  url: '/flow/documentBill/countermand',
  method: 'post',
  data
})
export const wxTransform = params => axios({
  url: '/wx/transform',
  method: 'get',
  params
})
export const wxRealFile = params => axios({
  url: '/wx/getRealFile',
  method: 'get',
  params
})
/**
 * 获取企业列表
 * @param {*} params
 * @returns
 */
export const wxCorpPage = params => axios({
  url: '/wx/corp/page',
  method: 'get',
  params
})
/**
 * 编辑
 * @param {*} data
 * @returns
 */
export const wxCorpEdit = data => axios({
  url: 'wx/corp/edit',
  method: 'post',
  data
})
/**
 * 编辑授权日期
 * @param {*} data
 * @returns
 */
 export const wxOvertime = data => axios({
  url: 'wx/corp/overtime',
  method: 'post',
  data
})